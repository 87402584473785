import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../layout'
import SEO from "../components/SEO/SEO";

import config from "../../data/SiteConfig";

export default function FourOhFour() {
  return (
    <Layout>
      <Helmet title={`404 | ${config.siteTitle}`} />
      <SEO />
      <div className="container text-center">
        <header>
          <h1>404</h1>
          <p className="subtitle">This was probably a mistake.</p>
        </header>
      </div>
    </Layout>
  )
}
